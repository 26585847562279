<template>
  <div class="register-container">
    <div class="banner">
      <div class="banner_img">
        <h2>{{ $t("L_RESTPASSWORD.BANNER_0002") }}</h2>
      </div>
    </div>
    <div class="register-form">
      <el-form ref="registerForm" :model="registerForm" :rules="rules">
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="password" ref="password" label="用户名">
          <el-input
            type="password"
            v-model="registerForm.password"
            auto-complete="new-password"
            :placeholder="$t('L_RESTPASSWORD.FORM_0002')"
            @focus="handleFocus('password')"
            @blur="handleBlur('password')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip6">
            {{ $t("L_RESTPASSWORD.TIP_0004") }}
          </div>
        </el-form-item>
        <el-form-item
          prop="confirmPassword"
          ref="confirmPassword"
          label="用户名"
        >
          <el-input
            type="password"
            v-model="registerForm.confirmPassword"
            auto-complete="confirm-password"
            :placeholder="$t('L_RESTPASSWORD.FORM_0003')"
            @focus="handleFocus('confirmPassword')"
            @blur="handleBlur('confirmPassword')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip7">
            {{ $t("L_RESTPASSWORD.TIP_0005") }}
          </div>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="registering"
            @click.native.prevent="handleSubmit"
            class="btn"
            >{{ $t("L_RESTPASSWORD.BUTTON_0002") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import { resetPassword } from "@/api/login";
export default {
  name: "register",
  data() {
    let validatePassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if (value === "") {
        callback(new Error(this.$t("L_RESTPASSWORD.FORM_0002")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_RESTPASSWORD.TIP_0003")));
        } else {
          return callback();
        }
      }
    };
    let validateConfirmPassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if (value === "") {
        callback(new Error(this.$t("L_RESTPASSWORD.FORM_0003")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_RESTPASSWORD.TIP_0003")));
        } else if (value !== this.registerForm.password) {
          callback(new Error(this.$t("L_RESTPASSWORD.TIP_0006")));
        } else {
          return callback();
        }
      }
    };
    return {
      // 表单参数
      registerForm: {
        password: "",
        confirmPassword: "",
      },
      rules: {
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "blur",
          },
        ],
      },
      registering: false,
      isFormTip6: false,
      isFormTip7: false,
    };
  },
  methods: {
    handleFocus(param) {
      this.$refs["registerForm"].clearValidate([param]);
      if (param === "password") {
        this.isFormTip6 = true;
      } else if (param === "confirmPassword") {
        this.isFormTip7 = true;
      }
    },
    handleBlur(param) {
      if (param === "password") {
        this.isFormTip6 = false;
      } else if (param === "confirmPassword") {
        this.isFormTip7 = false;
      }
    },
    // 登录
    handleSubmit() {
      this.registering = true;
      let phone = this.$route.params.phone
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          let param = {
            password: this.registerForm.password,
            phone: phone
          };
          resetPassword(param).then((res) => {
            if (res.code === 200) {
              this.registering = false;
              this.handleMessaage(
                this.$t("L_RESTPASSWORD.TIP_0013"),
                "success"
              );
              this.resetForm();
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 1500);
            } else {
              this.registering = false;
              if (res.msg) {
                let msg = res.msg;
                this.handleMessaage(msg, "error");
              } else {
                this.handleMessaage(
                  this.$t("L_RESTPASSWORD.TIP_0012"),
                  "error"
                );
              }
              this.resetForm();
              setTimeout(() => {
                this.$router.push({ name: "phoneVerification" });
              }, 1500);
            }
          });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.registering = false;
        }
      });
    },
    resetForm() {
      this.registerForm.password = ""
      this.registerForm.confirmPassword = ""
      this.$refs["registerForm"].clearValidate(["password"]);
      this.$refs["registerForm"].clearValidate(["confirmPassword"]);
    },
    handleMessaage(text, type) {
      this.$message({
        message: text,
        type: type,
        offset: 100,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/register";
</style>